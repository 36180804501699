import React from 'react';
import { Switch, Route } from "react-router-dom";
import { Pages } from '../Constants/Pages';
import { PageType } from '../Components/Pages/PageModel';
import { Callback } from '../Components/Auth/Callback';
import { Logout } from '../Components/Auth/Logout';
import { LogoutCallback } from '../Components/Auth/LogoutCallback';
import { SilentRenew } from '../Components/Auth/SilentRenew';
import { PrivateRoute } from './PrivateRoute';


export const PageTitle = <Switch>
  <Route exact={true} path="/signin-oidc" render={() => 'Loading'} />
  <Route exact={true} path="/logout" render={() => 'Loading'} />
  <Route exact={true} path="/logout/callback" render={() => 'Loading'} />
  {Pages.filter((page) => page.route).map((page, index) => (
    <Route key={index} path={page.route!.path} exact={page.route!.exactPath} render={() => page.title} />
  ))}
  <Route render={() => Pages.filter(page => page.pageType === PageType.Page404)[0].title}></Route>
</Switch>

export const PageContent = <Switch>
  <Route exact={true} path="/signin-oidc" component={Callback} />
  <Route exact={true} path="/logout" component={Logout} />
  <Route exact={true} path="/logout/callback" component={LogoutCallback} />
  <Route exact={true} path="/silentrenew" component={SilentRenew} />
  {Pages.filter((page) => page.route).map((page, index) => (
    page.pageType === PageType.PublicPage ?
      <Route key={index} path={page.route!.path} exact={page.route!.exactPath} component={page.page} />
      : <PrivateRoute key={index} path={page.route!.path} exact={page.route!.exactPath} component={page.page} />
  ))}
  <Route component={Pages.filter(page => page.pageType === PageType.Page404)[0].page} />
</Switch>
