import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { HomePageConst, SamplePageConst } from '../../Constants/Pages';
import { IPage } from '../Pages/PageModel';

// https://material-ui.com/guides/typescript/#usage-of-component-property
// https://material-ui.com/guides/composition/#react-router


const getListItem = (page: IPage) =>(
  <ListItem button component={page.link}>
    {page.icon}
  <ListItemText primary={page.title} />
  </ListItem>
);

export const mainListItems = (
  <div>
    {getListItem(HomePageConst)}
    {getListItem(SamplePageConst)}
  </div>
);

