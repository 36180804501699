import BaseService from './BaseService';
import { IAuthContext } from '../Contexts/AuthContext';


export interface IActorService {
    getActors(): Promise<any>;
}

export default class ActorService extends BaseService implements IActorService {
    constructor(authContext: IAuthContext | null) {
        super(authContext);
        console.log('new actor service');
    }
    getActors = async () =>  {
        
        const data = await this.api.get('Actors');
        return data;
    }    
}
