import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AccountCircleOutlined from '@material-ui/icons/AccountCircleOutlined';
import ExitToApp from '@material-ui/icons/ExitToApp';
import { AuthContext, IAuthContext } from '../../Contexts/AuthContext';
import { Tooltip, Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';

interface UserMenuState {
  anchorEl: Element | null;
}

export const UserMenu: React.FunctionComponent = () => {
  const [state, setState] = React.useState<UserMenuState | null>(null);
  const authContext = React.useContext<IAuthContext | null>(AuthContext);
  const userProfile = authContext!.getUserProfile();

  function handleClick(event) {
    setState({ anchorEl: event.currentTarget });
  }

  function handleClose() {
    setState({ anchorEl: null });
  }

  function handleLogout() {
    authContext!.logout();
  }

  function handleLogin() {
    authContext!.signinRedirect();
  }

  const menu = (
    <Menu
      anchorEl={state && state.anchorEl}
      keepMounted
      open={Boolean(state && state.anchorEl)}
      onClose={handleClose}
    >
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <ExitToApp />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </MenuItem>
    </Menu>
  );

  return userProfile.isAuthenticated ? (
    <>
      <Tooltip title={userProfile.userName}>
        <IconButton color="inherit" onClick={handleClick}>
          <AccountCircle />
        </IconButton>
      </Tooltip>
      {menu}
    </>
  ) : (
    <IconButton color="inherit" onClick={handleLogin}>
      <AccountCircleOutlined />
    </IconButton>
  );
};
