import React from "react";
import { Route } from "react-router-dom";
import { AuthConsumer } from "../Contexts/AuthContext";

export const PrivateRoute = ({ component, ...rest }) => {
    const renderFn = (Component) => (props) => (
        <AuthConsumer>
            {(authContext) => {
                if (!!Component && authContext!.isAuthenticated()) {
                    return <Component {...props} />;
                } else {
                    authContext!.signinRedirect();
                    return <span>loading</span>;
                }
            }}
        </AuthConsumer>
    );

    return <Route {...rest} render={renderFn(component)} />;
};