import React, { Component } from 'react';
import { hot } from 'react-hot-loader/root';
import './App.css';
import Dashboard from './Components/Layout/Dashboard';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { myTheme } from './Styles/Theme';
import { ServicesProvider } from './Contexts/ServicesContext';
import { AuthProvider, AuthConsumer } from './Contexts/AuthContext';


class App extends Component {

  render() {
    return (
      <AuthProvider>
        <AuthConsumer>
          {authService => (
            <ServicesProvider authContext={authService!} >
            <Router>
              <MuiThemeProvider theme={myTheme} >
                <Dashboard />
              </MuiThemeProvider>
            </Router>
          </ServicesProvider>
          )}
        </AuthConsumer>
      </AuthProvider>
    );
  }
}

export default hot(App);
