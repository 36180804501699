import { IRoute } from '../../Routes/IRoute';


export enum PageType {
  PublicPage,
  PrivatePage,
  Page404,
  Page500,
}

export interface IPage {
  pageType: PageType;
  title: string;
  route?: IRoute;
  page: any;
  icon?: any;
  link?: any;
}



