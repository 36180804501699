import React from 'react';
import DecoratedPage from '../Layout/PageLayout';
import { Button } from '@material-ui/core';

interface HomePageState {
    tab: string;
}

export default class HomePage extends React.Component<any, HomePageState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            tab: "primary"
        };
    }

    handleClick = (tab: string) => {
        this.setState({ tab: tab });
    }

    public render() {
        const { tab } = this.state;
        return (
            <DecoratedPage>
                <h1>Text de la home page</h1>
                <Button color="primary" onClick={() => this.handleClick("primary")} >
                    Primary
                </Button>
                <Button color="secondary" onClick={() => this.handleClick("secondary")}>
                    Secondary
                </Button>
                {tab === "primary"
                    ? "Button Primary"
                    : "Button Secondary"}
            </DecoratedPage>
        )
    }
}