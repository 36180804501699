import { IApiFetcher, ApiFetcher } from "../Infrastructure/Api/ApiFetcher";
import { ApiConfig } from "../Infrastructure/Api/ApiConfig";
import React from "react";
import { IAuthContext, AuthContext } from "../Contexts/AuthContext";

export default class BaseService {
    protected api: IApiFetcher;

    constructor(authContext: IAuthContext | null) {
        let apiConfig: ApiConfig = {
            hasAuthorization:JSON.parse(process.env.REACT_APP_API_HAS_Authorization!),
            url: process.env.REACT_APP_API_URL!,
        };

        if (authContext) {
            const userProfile = authContext!.getUserProfile();
            if (userProfile && userProfile.isAuthenticated) {
                apiConfig.token = userProfile.accessToken;
            }
        }
    
        this.api = new ApiFetcher(apiConfig);
    }
}