import React from 'react';
import DecoratedPage from '../Layout/PageLayout';
import { getCurrentPath } from '../../Common/Utils';

export default class Page404 extends React.Component {
    componentDidMount() {
        console.log('page 404 : ' + getCurrentPath());
    }

    public render() {
        return (
            <DecoratedPage>
                <h1>Page 404</h1>
            </DecoratedPage>
        )
    }
}