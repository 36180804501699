import React from 'react';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import HomePage from '../Components/Pages/HomePage'
import SamplePage from '../Components/Pages/SamplePage';
import { Link } from 'react-router-dom';
import { ServicesConsumer } from '../Contexts/ServicesContext';
import Page404 from '../Components/Pages/Page404';
import { IPage, PageType } from '../Components/Pages/PageModel';

const HomePageLink = (props: any) => <Link to="/" {...props} />;
const SamplePageLink = (props: any) => <Link to="/SamplePage" {...props} />;

export const HomePageConst: IPage = {
    pageType: PageType.PublicPage,
    title: 'Home Page',
    route: {
        exactPath: true,
        path: '/',
    },
    link: HomePageLink,
    icon:
        <ListItemIcon>
            <DashboardIcon />
        </ListItemIcon>
    ,
    page: () => <HomePage />,
};

export const SamplePageConst: IPage = {
    pageType: PageType.PrivatePage,
    title: 'Sample Page',
    route: {
        path: '/SamplePage',
        exactPath: true,
    },
    link: SamplePageLink,
    icon:
        <ListItemIcon>
            <ShoppingCartIcon />
        </ListItemIcon>
    ,
    page: () =>
        <ServicesConsumer>
            {services => (
                <SamplePage actorService={services!.getActorService()} />
            )}
        </ServicesConsumer>
};

export const Page404Const: IPage = {
    pageType: PageType.Page404,
    title: 'Page 404',
    page: () => <Page404 />,
};

export const Pages: Array<IPage> = [
    HomePageConst,
    SamplePageConst,
    Page404Const,
];