import React from "react";
import { AuthConsumer } from "../../Contexts/AuthContext";

export const Logout = () => (
    <AuthConsumer>
        {(authContext) => {
            authContext!.logout();
            return <span>loading</span>;
        }}
    </AuthConsumer>
);