import { createMuiTheme } from '@material-ui/core/styles';
import { blue, pink } from '@material-ui/core/colors';


export const myTheme = createMuiTheme({
    palette: {
      primary: blue,
      secondary: pink,
    }
  });

  export const appLogo = require('./logo.png');