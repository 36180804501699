import React, {Component} from "react";
import { AuthService } from "../Infrastructure/Identity/AuthService";


export interface IUserProfile {
    isAuthenticated: boolean,
    accessToken?: string | null | undefined,
    userId?: string | null | undefined,
    userName?: string | null | undefined,
    userEmail?: string | null | undefined,
}

export interface IAuthContext {
    signinRedirectCallback: () => void,
    logout: () => void,
    signoutRedirectCallback: () => void,
    isAuthenticated: () => ({}),
    signinRedirect: () => void,
    signinSilentCallback: () => void,
    createSigninRequest: () => ({}),
    getUserProfile: () => IUserProfile
}

export const AuthContext = React.createContext<IAuthContext | null>(null);

export const AuthConsumer = AuthContext.Consumer;

export class AuthProvider extends Component {
    authService: AuthService;
    constructor(props) {
        super(props);
        this.authService = new AuthService();
    }
    render() {
        return <AuthContext.Provider value={this.authService}>{this.props.children}</AuthContext.Provider>;
    }
}

export const withAuthContext = (Component) => {
    return function fn(props) {
      return (
        <AuthContext.Consumer>
            {(context) => <Component {...props} {...context} />}
        </AuthContext.Consumer>
      );
    };
   };