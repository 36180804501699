import React from 'react';
import DecoratedPage from '../Layout/PageLayout';
import { IActorService } from '../../Services/ActorService';

interface SamplePageProps {
    actorService: IActorService
}

interface SamplePageState {
    actors: any;
}

export default class SamplePage extends React.Component<SamplePageProps, SamplePageState> {
    constructor(props) {
        super(props);
        this.state = {
            actors: []
        };
    }

    componentDidMount() {
        this.props.actorService.getActors()
            .then((data) => this.setState({ actors: data}));
    }
    public render() {
        const { actors } = this.state;
        return (
            <DecoratedPage>
                { actors.map((actor) => {
                    return (
                        <div>
                            {actor.firstname} {actor.lastname} <br/>
                        </div>  
                    )
                })}
            </DecoratedPage>
        )
    }
}