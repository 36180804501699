import { UserManager, WebStorageStateStore, Log, UserManagerSettings } from 'oidc-client';
import { IAuthContext } from '../../Contexts/AuthContext';
import { getRootPath } from '../../Common/Utils';


export const userManagerSettings: UserManagerSettings = {
    authority: process.env.REACT_APP_IDENTITY_SERVER,
    client_id: process.env.REACT_APP_CLIENT_ID,
    redirect_uri: getRootPath() + '/signin-oidc',
    response_type: 'id_token token',
    scope: process.env.REACT_APP_CLIENT_SCOPE, 
    post_logout_redirect_uri: getRootPath() + '/logout/callback',
    loadUserInfo: true,
};

export class AuthService implements IAuthContext {
    private UserManager: UserManager;
    private accessToken;
    constructor() {
        this.UserManager = new UserManager({
            ...userManagerSettings,
            userStore: new WebStorageStateStore({ store: window.localStorage })
        });

        // Logger
        Log.logger = console;
        Log.level = Log.ERROR;

        this.UserManager.events.addUserLoaded(user => {
            this.accessToken = user.access_token;
            localStorage.setItem("access_token", user.access_token);
            localStorage.setItem("id_token", user.id_token);
            this.setUserInfo({
                accessToken: this.accessToken,
                idToken: user.id_token
            });
            this.setUserProfile(user.profile)
            if (window.location.href.indexOf("signin-oidc") !== -1) {
                this.navigateToScreen();
            }
        });
        this.UserManager.events.addSilentRenewError(e => {
            console.log("silent renew error", e.message);
        });

        this.UserManager.events.addAccessTokenExpired(() => {
            console.log("token expired");
            this.signinSilent();
        });
    }

    login() {
        this.UserManager.signinRedirect();
    }

    signinRedirectCallback = () => {
        this.UserManager.signinRedirectCallback().then(() => {
            "";
        });
    };

    getUser = async () => {
        const user = await this.UserManager.getUser();
        if (!user) {
            return await this.UserManager.signinRedirectCallback();
        }
        return user;
    };

    parseJwt = token => {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        return JSON.parse(window.atob(base64));
    };

    setUserInfo = authResult => {
        const data = this.parseJwt(this.accessToken);

        this.setSessionInfo(authResult);
        this.setUser(data);
    };

    signinRedirect = () => {
        localStorage.setItem("redirectUri", window.location.pathname);
        this.UserManager.signinRedirect({});
    };

    setUserProfile = data => {
        localStorage.setItem("userProfile", JSON.stringify(data));
    }

    setUser = data => {
        localStorage.setItem("userId", data.sub);
    };

    navigateToScreen = () => {
        const redirectUri = !!localStorage.getItem("redirectUri")
            ? localStorage.getItem("redirectUri")
            : "/";

        window.location.replace(redirectUri!);
    };

    setSessionInfo(authResult) {
        localStorage.setItem("access_token", authResult.accessToken);
        localStorage.setItem("id_token", authResult.idToken);
    }

    isAuthenticated = () => {
        const access_token = localStorage.getItem("access_token");
        return !!access_token;
    };

    getUserProfile = () => {
        if (this.isAuthenticated()) {
            let profile = JSON.parse(localStorage.getItem("userProfile")!);
            let access_token = localStorage.getItem("access_token");
            return {
                isAuthenticated: true,
                userId: profile.sub,
                userName: profile.name,
                userEmail: profile.email,
                accessToken: access_token
            }
        } else {
            return { isAuthenticated: false };
        }
    }

    signinSilent = () => {
        this.UserManager.signinSilent()
            .then(user => {
                console.log("signed in", user);
            })
            .catch(err => {
                console.log(err);
            });
    };
    signinSilentCallback = () => {
        this.UserManager.signinSilentCallback();
    };

    createSigninRequest = () => {
        return this.UserManager.createSigninRequest();
    };

    logout = () => {
        this.UserManager.signoutRedirect({
            id_token_hint: localStorage.getItem("id_token")
        });
        this.UserManager.clearStaleState();
    };

    signoutRedirectCallback = () => {
        this.UserManager.signoutRedirectCallback().then(() => {
            localStorage.clear();
            window.location.replace("/");
        });
        this.UserManager.clearStaleState();
    };

}
