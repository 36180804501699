// import NotificationToast from 'components/common/NotificationToast';
import qs from 'qs';
import axios, { AxiosRequestConfig } from 'axios';
import { ApiConfig } from './ApiConfig';
import React from 'react';
import { IAuthContext, AuthContext } from '../../Contexts/AuthContext';

export interface IApiFetcher {
  get(endpoint: string, options?: AxiosRequestConfig): Promise<any>;

  post(endpoint: string, dataToPost: any, options?: AxiosRequestConfig): Promise<any>;

  put(endpoint: string, dataToPost: any, options?: AxiosRequestConfig): Promise<any>;

  delete(endpoint: string, id?: number | string, options?: AxiosRequestConfig): Promise<any>;

  deleteList(endpoint: string, ids: number[] | string, options?: AxiosRequestConfig): Promise<any>;

  patch(endpoint: string, data: any, options?: AxiosRequestConfig): Promise<any>;

  postFile(endpoint: string, data: any, options?: AxiosRequestConfig): Promise<any>;
}

export class ApiFetcher implements IApiFetcher {
  constructor(private config: ApiConfig) {

    axios.defaults.paramsSerializer = (params) => qs.stringify(params, { allowDots: true });
    axios.interceptors.response.use(
      (res) => res,
      (error) => {
        // let errorMessage =
        //   error && error.response && error.response.data && error.response.data.message
        //     ? error.response.data.message
        //     : error;
        // NotificationToast.showError(`${errorMessage}`, {
        //   timeout: 5,
        // });
        throw error;
      },
    );
  }

  private combineUrl(endpoint: string): string {
    return `${this.config.url}/${endpoint}`;
  }

  private getOptions(options?: AxiosRequestConfig): AxiosRequestConfig {
    if (this.config.hasAuthorization) {
      let additionnalHeaders: { Authorization?: string } = {};
      if (this.config.token) {
        additionnalHeaders = {
          Authorization: `Bearer ${this.config.token!}`,
        };
      }
      return {
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          ...additionnalHeaders,
        },
        ...options,
      };
    } else {
      return {
        ...options
      };
    }
  }

  public async get(endpoint: string, options?: AxiosRequestConfig) {
    try {
      const finalOptions = this.getOptions(options);
      const response = await axios.get(this.combineUrl(endpoint), finalOptions);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public async post(endpoint: string, dataToPost: any, options?: AxiosRequestConfig) {
    try {
      const finalOptions = this.getOptions(options);
      const response = await axios.post(this.combineUrl(endpoint), dataToPost, finalOptions);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public async put(endpoint: string, dataToPost: any, options?: AxiosRequestConfig) {
    try {
      const finalOptions = this.getOptions(options);

      const response = await axios.put(this.combineUrl(endpoint), dataToPost, finalOptions);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public async delete(endpoint: string, id?: number | string, options?: AxiosRequestConfig) {
    try {
      const finalOptions = this.getOptions(options);

      let url = this.combineUrl(endpoint);
      if (id) {
        url += '/' + id;
      }

      const response = await axios.delete(url, finalOptions);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public async deleteList(endpoint: string, ids: number[] | string, options?: AxiosRequestConfig) {
    try {
      const finalOptions = this.getOptions({
        data: ids,
        ...options,
      });

      const response = await axios.delete(this.combineUrl(endpoint), finalOptions);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public async patch(endpoint: string, data: any, options?: AxiosRequestConfig): Promise<any> {
    try {
      const finalOptions = this.getOptions(options);

      const response = await axios.patch(this.combineUrl(endpoint), data, finalOptions);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  public async postFile(endpoint: string, data: any, options?: AxiosRequestConfig) {
    try {
      const finalOptions = this.getOptions(options);

      const response = await axios.post(this.combineUrl(endpoint), data, finalOptions);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
