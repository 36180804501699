import ActorService, { IActorService } from "../Services/ActorService";
import React, { Component } from "react";
import { IAuthContext, AuthContext } from "./AuthContext";

export interface IServiceContext {
  getActorService: () => ActorService 
  }

export class ServicesFactory implements IServiceContext {
  private AuthContext: IAuthContext;
  constructor(authContext :IAuthContext ) {
      this.AuthContext = authContext;
    }

    getActorService = () => {
      return new ActorService(this.AuthContext);
    }
}

export interface IServiceProviderProps {
  authContext: IAuthContext
}

// export const ServicesFactory2 = () => {
//   const authContext = React.useContext<IAuthContext | null>(AuthContext);
//   let serviceContext : IServiceContext = {
//     userService: new UserService(authContext)
//   }
//   return serviceContext;
// }

const ctx = React.createContext<IServiceContext | null >(null);
export const ServicesConsumer = ctx.Consumer;

export class ServicesProvider extends Component<IServiceProviderProps> {
    serviceFactory: ServicesFactory;
    constructor(props: IServiceProviderProps) {
      super(props);
      this.serviceFactory = new ServicesFactory(props.authContext)
    }

    render() {
        return <ctx.Provider value={this.serviceFactory} >{this.props.children}</ctx.Provider>;
    }
}

//export const ServicesProvider = ctx.Provider;
